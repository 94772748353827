import React from 'react';
import MainHeader from '../../components/MainHeader';
import CompanyOverview from '../../components/ComapnyOverview'
import OurServices from '../../components/OurServices';
import '../home/home.css';

const Home = () => {
  return (
    <main>
      <MainHeader />
      <section>
        <CompanyOverview />
        <OurServices />
      </section>
      <section className="calendar-section">
        {/* Google Calendar Appointment Scheduling */}
        <iframe
          src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ2RavohHO7MGq-HHQKdAx9vTFljD_ph2vBVCwYFEHVpgPQ72t16OgkDD3F1RyAyEpiNKoH4jnZb?gv=true"
          style={{
            border: 0,
            width: '100%',
            height: '100vh',  // Full screen height
          }}
          frameBorder="0"
          title="Google Calendar Scheduler"
          onError={() => console.error("Failed to load calendar")}
        ></iframe>
      </section>
    </main>
  );
};

export default Home;
